import { OrderExpectWaitStatusEnum, SiteDispatchStatusRes } from '@/api/generated/types'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import StaticImg from '@/components/common/StaticImg'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import { DateUtils } from '@/utils/date'
import isEmpty from 'lodash/isEmpty'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'

export type RobotStatusBottomSheetDataType = {
  robotStatus?: SiteDispatchStatusRes
}

type RobotStatusBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType<RobotStatusBottomSheetDataType>
}

const OrderExpectTitleText = {
  [OrderExpectWaitStatusEnum.WAIT]: '고객님 죄송해요!\n배달이 밀려서 대기 시간이 필요해요',
  [OrderExpectWaitStatusEnum.UNKNOWN]: '고객님 죄송해요!\n배달이 밀려서 대기 시간이 필요해요',
  [OrderExpectWaitStatusEnum.UNAVAILABLE]: '고객님 죄송해요!\n정비를 위해 배달을 잠시 중단할게요'
}

const RobotStatusBottomSheet = ({ bottomSheetControls }: RobotStatusBottomSheetProps) => {
  const { bottomSheetData, handleClose } = bottomSheetControls
  const robotStatus = bottomSheetData?.robotStatus

  if (robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.AVAILABLE) {
    return null
  }

  const orderExpectTitleTextDescriptionText =
    robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.UNAVAILABLE
      ? '신속히 정비 중이니 잠시 후 다시 찾아주세요'
      : ''

  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls} background="bg-primary-100">
      <div className="min-h-40 w-full" />
      <div className="flex w-full flex-col items-center justify-center">
        <span className="title4 whitespace-pre-line text-center font-bold">
          {robotStatus?.orderExpectWaitStatus && OrderExpectTitleText[robotStatus.orderExpectWaitStatus]}
        </span>
        {!isEmpty(orderExpectTitleTextDescriptionText) && (
          <span className="body3 mt-8 whitespace-pre-wrap text-center font-medium text-gray-700">
            {orderExpectTitleTextDescriptionText}
          </span>
        )}
        <div className="h-12 w-full" />
        <StaticImg className="h-150 w-150" src="/images/home-robot-status.png" alt="로봇 상태 바텀시트 뉴비그림" />
        {robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.WAIT && (
          <div className="flex w-190 flex-col justify-center">
            <span className="caption text-center">배달 가능한 시간까지</span>
            <span className="title2 text-center font-bold">
              {DateUtils.secToMinSec(bottomSheetData.robotStatus?.orderExpectWaitSec ?? 0)}
            </span>
          </div>
        )}
      </div>
      <div className="h-30 w-full" />
      <CTAButton divider={false} background="bg-primary-100">
        <Button full theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.ExtraLarge} onClick={() => handleClose()}>
          <span className="body1 font-bold">확인했어요!</span>
        </Button>
      </CTAButton>
    </BottomSheetLegacy>
  )
}

export default RobotStatusBottomSheet
